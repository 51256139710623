import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import SEO from "../components/seo";

const KontaktDziekujemy = () => {
  return (
    <Layout>
      <SEO
        title="Kontakt - Podziękowania"
        description="Dziękujemy za zainteresowanie systemem QuickCRM! Skontaktujemy się z Tobą tak szybko, jak to tylko możliwe."
      />
      <div
        style={{
          // height: "calc(100vh - 60px)",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          color: "hsl(0, 0%, 97%)"
        }}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            backgroundColor: "#62abd3",
            padding: 80
          }}
        >
          <h1
            style={{
              fontSize: 48
            }}
          >
            Dziękujemy
          </h1>
          <p
            style={{
              color: "hsl(0, 0%, 97%)",
              fontSize: 32
            }}
          >
            za zainteresowanie systemem QuickCRM!
          </p>
        </div>

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            backgroundColor: "#66b262",
            padding: 80
          }}
        >
          <p
            style={{
              color: "hsl(0, 0%, 97%)",
              fontSize: 32,
              lineHeight: "1.3em"
            }}
          >
            Dziękujemy za wypełnienie formularza kontaktowego. Skontaktujemy się
            z Tobą tak szybko, jak to możliwe i odpowiemy na wszystkie Twoje
            pytania!
          </p>
          <p
            style={{
              color: "hsl(0, 0%, 97%)",
              fontSize: 32
            }}
          >
            Zapraszamy do zapoznania się z{" "}
            <Link
              style={{
                textDecoration: "none",
                fontWeight: "bold",
                color: "hsl(0, 0%, 97%)"
              }}
              to="/funkcje-systemu-crm/"
            >
              funkcjami systemu QuickCRM
            </Link>
            .
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default KontaktDziekujemy;
